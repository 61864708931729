@use "assets/theme/variables/palette";

.root {
  border-radius: 20px;
}

.NEW {
  color: palette.$jet800 !important;
}

.PENDING {
  color: palette.$sealBrown800 !important;
}

.PAUSED {
  color: palette.$sealBrown800 !important;
}

.RUNNING {
  color: palette.$forestGreen800 !important;
}

.DONE {
  color: palette.$prussianBlue800 !important;
}

.ERROR {
  color: palette.$poppy500 !important;
}
