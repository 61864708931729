@use 'assets/theme/variables/palette';
@use 'assets/theme/variables/spacers';

.root {
  padding: calc(#{spacers.$spacer3} - 3px);

  &:hover, &:active {
    background-color: palette.$platinum100 !important;
  }

  &:active {
    border: 1px solid palette.$argentinianBlue500;
    outline: 2px solid palette.$prussianBlue50 !important;
  }
}

.icon {
  color: palette.$argentinianBlue500;
  display: block;
  cursor: pointer;
}
