@use 'assets/theme/variables/sizes';

.menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
  min-width: min-content;
  > a {
    display: flex;
    align-items: center;
  }
}

.root {
  width: 100%;
  max-height: sizes.$headerHeight;
}

.avatar {
  cursor: pointer;
}
