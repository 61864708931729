@use "assets/theme/variables/spacers";

.parametersContent {
  width: 445px;
  > div:not(:last-child) {
    margin-bottom: spacers.$spacer3;
  }
}

.shift {
  padding-left: spacers.$spacer7;
}
