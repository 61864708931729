@use "assets/theme/variables/colors";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/borders";

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: borders.$borderRadiusXs;
  padding: spacers.$spacer2;
  &:hover {
    :global(.ant-typography) {
      color: colors.$primaryColorHover;
    }
    :global(.item-icon-image) {
      border: 1px solid colors.$primaryColorHover;
    }
  }
  &:active {
    :global(.ant-typography) {
      color: colors.$primaryColorActive;
    }
    :global(.item-icon-image) {
      border: 1px solid colors.$primaryColorActive;
    }
  }
}

.selected {
  :global(.ant-typography) {
    color: colors.$primaryColor !important;
  }
  :global(.item-icon-image) {
    border: 1px solid colors.$primaryColor !important;
  }
}

.itemIconImage {
  background-color: colors.$pageBackground;
  height: spacers.$spacer7;
  width: spacers.$spacer7;
  border-radius: borders.$borderRadiusXs;
  box-sizing: border-box;
  border: 1px solid colors.$borderColor;
  background-position: center;
}
