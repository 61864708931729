@use "assets/theme/variables/palette";

.root {
  :global(.ant-badge-dot) {
    box-shadow: 0 0 0 2px palette.$white !important;
  }
}

.borderless {
  :global(.ant-badge-dot) {
    box-shadow: none !important;
  }
}
