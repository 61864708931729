@use 'assets/theme/variables/palette';
@use 'assets/theme/variables/spacers';

.table {
  height: 100%;

  > :global(.ant-spin-nested-loading) {
    height: 100%;
  }

  :global(.ant-table) {
    height: 100%;
    display: flex;
    flex-flow: column;

    :global(.ant-table-container) {
      flex: 1;
      min-height: 0;
      overflow: auto;
    }
  }
}

.folderRow {
  cursor: pointer
}

.icon {
  color: palette.$verdigris500
}

.actions {
  padding: 0 spacers.$spacer3;
}

.hidden {
  display: none;
}
