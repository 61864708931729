@use "assets/theme/variables/spacers";
@use "assets/theme/variables/colors";
@use "assets/theme/variables/palette";

$zIndexContent: 1;
$bgColor: palette.$blue50;

.root {
  height: 100%;
  overflow-y: auto;
  padding: 0 spacers.$spacer4;
  position: relative;
  background-color: $bgColor;
  display: flex;
  flex-direction: column;
}

.divider {
  margin: spacers.$spacer6 0;
}

.header {
  background-color: $bgColor;
  padding-top: spacers.$spacer6;
  position: sticky;
  top: 0;
  z-index: calc(#{$zIndexContent} + 1);
}

.content {
  z-index: $zIndexContent;
  flex: 1;
}

.footer {
  background-color: $bgColor;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: spacers.$spacer6 0;
  border-top: 1px solid colors.$borderColor;
  z-index: calc(#{$zIndexContent} + 1);
}
