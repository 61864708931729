@use 'assets/theme/variables/font';
@use 'assets/theme/variables/spacers';

.field {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.fieldTitle {
  display: flex;
  flex-direction: column;
  font-size: font.$fontSizeSmall;
}

.fieldValue {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 5px;
}
