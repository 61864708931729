@use "assets/theme/variables/font";

.header1 {
  font-size: font.$fontSizeH1;
  font-weight: 600;
  line-height: 33.6px;
}

.header2 {
  font-size: font.$fontSizeH2;
  font-weight: 600;
  line-height: 28.8px;
}

.header3 {
  font-size: font.$fontSizeH3;
  font-weight: 600;
  line-height: 24px;
}

.header4 {
  font-size: font.$fontSizeH4;
  font-weight: 500;
  line-height: 19.2px;
}

.body18 {
  font-size: font.$fontSizeXl;
  line-height: 21.6px;
}

.body16 {
  font-size: font.$fontSizeLg;
  line-height: 19.2px;
}

.body14 {
  font-size: font.$fontSizeBase;
  line-height: 16.8px;
}

.body12 {
  font-size: font.$fontSizeSmall;
  line-height: 14.4px;
}

.body10 {
  font-size: font.$fontSizeMd;
  line-height: 12px;
}

.bold {
  font-weight: 500;
}
