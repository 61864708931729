@use "assets/theme/variables/spacers";
@use "assets/theme/variables/colors";
@use "assets/theme/variables/borders";
@use "assets/theme/variables/sizes";

.root {
  background-color: colors.$mainBackgroundColor;
  padding: spacers.$spacer3;
  border-radius: borders.$borderRadius;
}

@mixin pagination-action-button {
  display: flex;
  align-items: center;
  & a {
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0 spacers.$spacer4;
  }
}

.pagination {
  display: flex;
  :global(.ant-pagination-total-text) {
    flex: 1;
    padding-left: spacers.$spacer4;
  }
  :global(.ant-pagination-prev) {
    @include pagination-action-button
  }
  :global(.ant-pagination-next) {
    @include pagination-action-button
  }
  :global(.ant-pagination-disabled) {
    & a {
      cursor: not-allowed;
      color: colors.$disabledTextColor;
    }
  }
}

.ellipsis {
  min-width: 120px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre;
}

.filterRoot {
  border-radius: borders.$borderRadius;
  background: white;
  padding: spacers.$spacer3;
  width: sizes.$columnFilterWidth;
}

.filterInputGroup {
  margin-bottom: spacers.$spacer3;
}

.filterInput {
  width: 100%;
}

.filterButtons {
  justify-content: flex-end;
  width: 100%;
}

.tableActionPanel {
  width: 400px;
}
