@use "assets/theme/variables/colors";
@use "assets/theme/variables/spacers";

.title {
  border-bottom: 2px solid colors.$borderColor;
  padding-bottom: spacers.$spacer3;
}

.closeButton {
  align-self: center;
}
