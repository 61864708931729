@use "assets/theme/variables/palette";
@use "assets/theme/variables/mixins";

html, body, #root, .ant-app {
  height: 100%;
}

#root {
  min-height: 100vh;
}

.ant-app {
  min-height: 100vh;
}

body {
  margin: 0;
}

#root {
  overflow: auto;
}

* {
  outline: 0 !important;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: palette.$platinum300;
  border-radius: 4px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: palette.$platinum300 transparent;
}

.shadow-sm {
  @include mixins.shadowMixin('sm');
}

.shadow {
  @include mixins.shadowMixin();
}

.shadow-md {
  @include mixins.shadowMixin('md');
}

.shadow-lg {
  @include mixins.shadowMixin('lg');
}

