@use 'assets/theme/variables/spacers';
@use 'assets/theme/variables/font';

.title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.indent {
  margin-bottom: spacers.$spacer7 !important;
}

.content {
  padding: 0 spacers.$spacer3;
  font-size: font.$fontSizeXxl;
}

.titleCol {
  display: inline-flex;
  align-items: center;
}
