@use "assets/theme/variables/font";
@use "assets/theme/variables/colors";

.popover {
  max-width: 400px;
}

.hint {
  color: colors.$placeholder;
}

.small {
  font-size: font.$fontSizeLg;
}

.default {
  font-size: font.$fontSizeXxl;
}
