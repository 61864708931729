@use "assets/theme/variables/spacers";

.icon {
  width: spacers.$spacer3;
  height: spacers.$spacer3;
  border-radius: spacers.$spacer3;
}

.item {
  display: flex;
  flex-wrap: nowrap;
}
