@use "assets/theme/variables/spacers";
@use "assets/theme/variables/colors";

.bordered {
  padding: calc(#{spacers.$spacer3} - 2px) spacers.$spacer3;
  border: 1px solid colors.$borderColor;
  border-radius: 20px;

  :global(.ant-checkbox)::after, :global(.ant-checkbox-inner) {
    border-radius: 10px !important;
  }
}
