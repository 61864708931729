@use "assets/theme/variables/spacers";

.instrumentPanel {
  position: absolute;
}

.left {
  left: spacers.$spacer8;
}

.right {
  right: spacers.$spacer8;
}

.top {
  top: spacers.$spacer6;
  align-items: start;
}

.bottom {
  bottom: spacers.$spacer6;
  align-items: end;
}
