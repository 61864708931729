@use 'assets/theme/variables/colors';
@use 'assets/theme/variables/spacers';
@use 'assets/theme/variables/sizes';
@use "assets/theme/variables/breakpoints";
@use "assets/theme/variables/mixins";

.root {
  height: 100%;
  min-height: 100vh;
}

.header {
  background: colors.$mainBackgroundColor !important;
  max-height: sizes.$headerHeight;
  height: sizes.$headerHeight !important;
  border-bottom: 1px solid colors.$borderColor;
  @include mixins.shadowMixin('sm');
  z-index: sizes.$headerZIndex;
  padding: spacers.$spacer4 spacers.$spacer8 !important;
  display: flex;
  align-items: center;
}

.logoBlock {
  display: flex;
  align-items: center;
}

.menu {
  background: colors.$mainBackgroundColor !important;
  border-right: 1px solid colors.$borderColor;
  @include mixins.shadowMixin('sm');
  padding: spacers.$spacer4;
  text-align: center;
}

.content {
  max-height: sizes.$fullHeight;
  overflow: auto;
}
