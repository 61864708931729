@use "assets/theme/variables/colors";
@use "assets/theme/variables/palette";
@use "assets/theme/variables/font";
@use "assets/theme/variables/spacers";

.title {
  margin-top: spacers.$spacer0 !important;
  margin-bottom: spacers.$spacer0 !important;
}

.requiredMark {
  margin-right: spacers.$spacer2;
}

.helpTooltip {
  color: palette.$gray500;
  cursor: help;
  margin-left: spacers.$spacer2;
}

.hideMarginBottom {
  margin-bottom: spacers.$spacer0 !important;
}

.viewableInput {
  margin-bottom: spacers.$spacer0 !important;
  :global(.ant-form-item-label) {
    padding-bottom: 0 !important;
    font-size: font.$fontSizeSmall;
    label {
      color: colors.$secondaryColor;
      font-size: inherit;
    }
  }
  :global(.ant-form-item-control-input) {
    position: relative;
    left: -15px;
    margin-top: calc(-1em + 13px);
    margin-bottom: calc(1em - 8px - 1px);
  }
}

.noInline {
  :global(.ant-form-item-control-input) {
    left: 0 !important;
  }
}

.noInput {
  margin-bottom: spacers.$spacer0 !important;
  :global(.ant-form-item-control-input) {
    display: none;
  }
}

.vertical {
  :global(.ant-form-item-row) {
    flex-flow: row nowrap;
    justify-content: space-between;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
    }

    label {
      width: 100%;

      &::before {
        align-self: flex-start;
        padding-top: spacers.$spacer2;
      }

      span:first-child {
        width: 75%;
      }
    }

    :global(.ant-form-item-control-input) {
      width: 100%;
    }
  }
}
