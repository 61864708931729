@use "assets/theme/variables/palette";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/borders";

.disclaimer {
  padding: spacers.$spacer3 spacers.$spacer6;
  border-radius: borders.$borderRadius;
  background-color: palette.$azure50;
}

.ul {
  margin-block-start: spacers.$spacer3;
  margin-block-end: 0;
}
