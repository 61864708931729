@use 'assets/theme/variables/spacers';

.root {
  > div:last-child {
    padding-top: spacers.$spacer1;
  }
}

.breadcrumbs {
  :global(.ant-breadcrumb-link) {
    display: flex;
    align-items: end;
  }

  > ol {
    li {
      max-width: 20%;
    }

    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.firstElement {
  :global(.ant-space-item):first-child {
    display: flex;
  }
}
