@use "assets/theme/variables/colors";
@use "assets/theme/variables/palette";

.hover {
  * {
    color: colors.$primaryColor !important;
  }

  :global(.ant-btn) {
    border: 1px solid colors.$primaryColor !important;
  }
}

.active {
  @extend .hover;

  :global(.ant-btn) {
    box-shadow: 0 0 2px 2px palette.$verdigris300;
  }
}

.ghost {
  :global(.ant-btn) {
    background-color: transparent !important;
    border-color: transparent;
  }
}

.root {
  cursor: pointer;
  text-align: center;

  &:not(.active), &:not(.ghost) {
    * {
      color: colors.$placeholder !important;
    }

    :global(.ant-btn) {
      background-color: palette.$blue50;
    }
  }

  &:not(.disabled) {
    &:hover, &:active {
      @extend .hover;
    }
  }
}

.disabled {
  cursor: not-allowed;
  * {
    color: colors.$disabledColor !important;
  }

  :global(.ant-btn) {
    background-color: colors.$mainBackgroundColor;
  }
}

.button {
  height: 50px;
}
