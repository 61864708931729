@use "assets/theme/variables/borders";
@use "assets/theme/variables/colors";
@use "assets/theme/variables/spacers";

.classDot {
  width: 8px;
  height: 8px;
  border-radius: borders.$borderRadiusRound;
}

.lockIcon {
  color: colors.$placeholder;
}

.button {
  height: fit-content;
}

.space {
  > div:last-child {
    display: contents;
  }
}

.classTable {
  :global(.ant-table-thead) {
    > tr {
      > th {
        font-weight: 500;
        padding: spacers.$spacer3;
        background-color: transparent !important;
        border-bottom: 1px solid colors.$borderColor !important;

        &:first-child {
          border-start-start-radius: 0 !important;
          border-end-start-radius: 0 !important;
        }

        &:last-child {
          border-start-end-radius: 0 !important;
          border-end-end-radius: 0 !important;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  :global(.ant-table-tbody) {
    > tr {
      > td {
        padding: spacers.$spacer3;
        border-bottom: none;
        line-height: 18px;
      }
    }
  }
}

.otClassificationRadio {
  > label {
    width: 100%;
  }
}
