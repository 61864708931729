$fontSizeXs: 8px;
$fontSizeMd: 10px;
$fontSizeSmall: 12px;
$fontSizeBase: 14px;
$fontSizeLg: 16px;
$fontSizeXl: 18px;
$fontSizeXxl: 20px;
$fontSizeXxxl: 24px;
$fontSizeH1: 28px;
$fontSizeH2: 24px;
$fontSizeH3: 20px;
$fontSizeH4: 16px;
