@use "assets/theme/variables/spacers";
@use "assets/theme/variables/palette";

.root {
  padding: spacers.$spacer6;
  border: 1px solid palette.$blue100;

  :global(.ant-form-item) {
    margin-bottom: spacers.$spacer3;
  }
}

.title {
  display: block;
}

.space {
  width: 100%;
}
