@use "assets/theme/variables/spacers";
@use "assets/theme/variables/colors";

.root {
  height: 100%;
}

.wrapper {
  height: 100%;

  :global(.ant-spin-blur:first-child) {
    opacity: 0 !important;
    max-height: 100vh;
    overflow: hidden;
  }

  :global(.ant-spin-container) {
    width: 100%;
    height: 100%;
  }
}

.content {
  padding: spacers.$spacer5;
  width: 100%;
  height: 100%;
}

.fullWidth {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.fullParent {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.indicator {
  color: colors.$primaryColor;
}
