@use "assets/theme/variables/spacers";
@use "assets/theme/variables/palette";

.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > *:first-child {
    margin-right: spacers.$spacer2;
  }

  :global(.ant-badge) {
    line-height: 1;
  }
}

.overlay {
  &:not(:global(.ant-popover-hidden)) {
    width: 400px;
    max-height: 300px;
    display: flex;

    :global(.ant-popover-content) {
      width: 100%;
    }

    :global(.ant-popover-inner) {
      padding: spacers.$spacer3 0;
      height: 100%;

      :global(.ant-popover-inner-content) {
        overflow-y: auto;
        height: 100%;
      }
    }
  }
}

.eventsIcon {
  cursor: pointer;
  color: palette.$blue700;
}
