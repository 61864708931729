.root {
  height: 100%;
  position: relative;
}

.spin {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
