.space {
  height: 100%;

  > :global(.ant-space-item):last-child {
    flex: 1;
    min-height: 0;
  }
}

.actions {
  > :global(.ant-space-item):last-child {
    flex: 1;
  }
}
