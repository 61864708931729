
$dataBlockWidth: 530px;

.content {
  padding: 0;
}

.root {
  height: 100%;
}

.dataBlock {
  min-width: $dataBlockWidth;
  width: $dataBlockWidth;
  max-width: $dataBlockWidth;
  height: 100%;
}
