@use "assets/theme/variables/spacers";

.settingsRadio {
  > label {
    width: 100%;
  }
}

.shootingRow {
  display: flex;
  justify-content: space-between;
  gap: spacers.$spacer5;

  & > div {
    flex: 1;
  }
}
