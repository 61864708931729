@use "assets/theme/variables/colors";
@use "assets/theme/variables/palette";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/font";
@use "assets/theme/variables/borders";

// Dropdowns
.ant-dropdown-menu {
  &:not(.ant-dropdown-menu-submenu) {
    padding: spacers.$spacer3 0 !important;
    border: 1px solid colors.$borderColor;
    min-width: 184px;
  }
  &.ant-dropdown-menu-submenu > .ant-dropdown-menu {
    margin-left: spacers.$spacer2;
  }
}

// Input
.ant-input {
  &-prefix, &-suffix, &-clear-icon {
    color: colors.$placeholder !important;
  }
  &-prefix, &-suffix {
    font-size: font.$fontSizeXl !important;
    line-height: font.$fontSizeXl;
  }
  &-clear-icon {
    font-size: font.$fontSizeLg !important;
    line-height: font.$fontSizeLg;
  }
  &-prefix {
    margin-inline-end: calc(#{spacers.$spacer3} + #{spacers.$spacer1}) !important;
  }
  &-suffix {
    margin-inline-start: calc(#{spacers.$spacer3} + #{spacers.$spacer1}) !important;
  }

  &-number {
    &-handler {
      &-wrap {
        width: 24px;
      }

      &-down-inner, &-up-inner {
        font-size: 9px !important;
      }
    }
  }
}

$cellSize: 32px;
$theadHeight: 34px;

// DatePicker
.ant-picker {
  &-date-panel, &-month-panel, &-year-panel {
    width: 304px !important;
  }

  &-month-panel, &-year-panel {
    .ant-picker-content {
      height: 180px !important;
    }
  }

  &-panel .ant-picker-date-panel .ant-picker-body {
    padding-top: 0 !important;
    position: relative;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: $theadHeight;
      top: 0;
      left: 0;
      background-color: palette.$verdigris25;
    }
  }

  &-content {
    width: 268px !important;

    thead {
      background-color: palette.$verdigris25;
      height: $theadHeight;
    }

    td, th {
      min-width: $cellSize !important;
      width: $cellSize !important;
      height: $cellSize !important;
    }

    th {
      color: colors.$placeholder !important;
    }
  }

  &-cell {
    &::before {
      height: $cellSize !important;
    }

    &:not(.ant-picker-cell-selected) &:not(.ant-picker-cell-range-start) &:not(.ant-picker-cell-range-end) {
      &:hover {
        div {
          background: palette.$verdigris50 !important;
        }
      }

      &:active {
        div {
          background-color: colors.$primaryColor;
          color: palette.$white;
        }
      }
    }

    &-range-start, &-range-end {
      &::before {
        background: palette.$verdigris50 !important;
      }
    }

    &-inner {
      min-width: $cellSize !important;
      height: $cellSize !important;
      line-height: $cellSize !important;
      font-weight: 500;
    }

    &-in-range {
      &::before {
        background: palette.$verdigris50 !important;
      }
    }

    &-range-hover, &-range-hover-start, &-range-hover-end {
      &:not(.ant-picker-cell-range-start), &:not(.ant-picker-cell-range-end) {
        &::before, .ant-picker-cell-inner::after {
          background: palette.$verdigris50 !important;
        }

        &::after {
          border-color: transparent !important;
        }
      }
    }
  }

  &-header {
    border-bottom: none !important;
    font-size: font.$fontSizeLg;

    button, &-view {
      line-height: 52px !important;
    }

    &-view {
      font-weight: 500 !important;
    }
  }
}

// Switch
.ant-switch {
  min-width: 40px;

  &:disabled {
    background: colors.$disabledColor;

    &.ant-switch-checked {
      background-color: colors.$disabledTextColor;
    }
  }
}

// Radio
.ant-radio {
  &-checked {
    .ant-radio-inner {
      &::after {
        transform: scale(0.5);
      }
    }

    &.ant-radio-disabled {
      .ant-radio-inner {
        border-color: colors.$disabledTextColor;
        &::after {
          background-color: palette.$white;
        }
      }
    }
  }

  &.ant-radio-disabled:not(.ant-radio-checked) {
    .ant-radio-inner {
      border-color: colors.$disabledTextColor;
      background-color: palette.$white;
    }
  }

  &-wrapper-disabled {
    color: palette.$platinum800;
  }
}

// Checkbox
.ant-checkbox {
  &.ant-checkbox-disabled {
    & + span {
      color: palette.$platinum800;
    }

    .ant-checkbox-inner {
      border-color: colors.$disabledTextColor;
    }

    &:not(.ant-checkbox-checked) {
      .ant-checkbox-inner {
        background: palette.$white;
      }
    }
  }
}

// Message
.ant-message {
  color: colors.$textColor;

  .ant-message-notice-content {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, \'Segoe UI\', sans-serif;
    font-weight: 500;
    font-size: font.$fontSizeBase;
    border-radius: borders.$borderRadius !important;
    padding: spacers.$spacer4 spacers.$spacer5 !important;
  }

  &-notice-success {
    .ant-message-notice-content {
      background-color: colors.$successBg !important;
    }
  }
  &-notice-error {
    .ant-message-notice-content {
      background-color: colors.$errorBg !important;
    }
  }
}

// Modals
.ant-modal {
  &-title {
    font-size: font.$fontSizeH3 !important;
  }

  &-content {
    padding: spacers.$spacer7 !important;
  }

  &-footer {
    border-top: 1px solid colors.$borderColor;
    padding-top: spacers.$spacer7;
  }

  &-confirm {
    &.error {
      .ant-modal-confirm-title {
        color: colors.$errorColor;
        font-size: font.$fontSizeXl;
        font-weight: 500;
      }
    }

    &-btns {
      border-top: 1px solid colors.$borderColor;
      padding-top: spacers.$spacer7;
      display: flex;

      > button {
        flex: 1;
      }
    }
  }
}

// Forms
.ant-form {
  &-item {
    &-label {
      margin-bottom: spacers.$spacer2;

      > label {
        font-weight: 500;
      }
    }

    &-required:not(.ant-form-item-required-mark-optional)::before {
      margin-inline-end: 0 !important;
    }
  }
}

.ant-table {
  &-thead {
    & .ant-table-cell {
      border-bottom: none !important;
    }
    & > tr {
      & > th {
        background-color: palette.$blue100 !important;
        &:first-child {
          border-start-start-radius: borders.$borderRadius !important;
          border-end-start-radius: borders.$borderRadius !important;
        }
        &:last-child {
          border-end-end-radius: borders.$borderRadius !important;
        }
      }
    }
  }
  &-footer {
    background-color: inherit !important;
  }
}

.ant-badge {
  color: inherit;
}

// Popover

.ant-popover {
  &-inner-content {
    white-space: pre-wrap;
  }
}
