@use "assets/theme/variables/spacers";
@use "assets/theme/variables/palette";
@use "assets/theme/variables/borders";
@use "assets/theme/variables/colors";
@use "assets/theme/variables/mixins";

.root {
  position: relative;
  cursor: pointer;

  @include mixins.shadowMixin();

  &:hover, &:focus, &:active {
    outline: 3px solid colors.$hoverBorderColor !important;
  }

  :global(.ant-card-body) {
    padding: spacers.$spacer6;
  }
}

.preview {
  max-height: 130px;
  height: 130px;
  object-fit: cover;
}

.title {
  height: 38px;

  :global(.ant-typography) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.divider {
  border-color: palette.$platinum400;
  margin: spacers.$spacer4 0;
}

.dateTag {
  width: fit-content;
  background-color: palette.$blue100;
  padding: spacers.$spacer1 spacers.$spacer3;
  border-radius: borders.$borderRadiusSm;
  color: palette.$platinum800;
}

.spin {
  cursor: pointer;
  :global(.anticon-spin) {
    color: palette.$blue700;
  }
}

.actions {
  padding-top: spacers.$spacer3;
}

.iconBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > *:first-child {
    margin-right: spacers.$spacer2;
  }

  :global(.ant-badge) {
    line-height: 1;
  }
}

.eventsIcon {
  cursor: pointer;
  color: palette.$blue700;
}

.menuButton {
  position: absolute;
  top: 20px;
  right: 20px;
  width: fit-content !important;
}
