@use 'assets/theme/variables/spacers';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 25vh !important;
}
.extra {
  & > * {
    margin-bottom: spacers.$spacer5;
  }
}
