.root {
  :global(.ant-dropdown-trigger) {
    cursor: pointer;
    > :global(.anticon-down) {
      display: none;
    }
  }

  a {
    display: inline-flex;
    height: auto;
  }
}
