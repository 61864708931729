@use "assets/theme/variables/colors";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/borders";

.cesium {
  &-infoBox {
    top: 80px;
    right: spacers.$spacer7;
    background: colors.$mainBackgroundColor;
    border-radius: borders.$borderRadiusMd !important;
    overflow: hidden;
    border: none;
    box-shadow: none;

    &-title {
      background: colors.$mainBackgroundColor;
      color: colors.$secondaryTextColor;
    }

    &-description {
      color: colors.$secondaryTextColor !important;
    }

    &-camera {
      display: none !important;
    }

    & button {
      fill: colors.$secondaryTextColor;
      color: colors.$secondaryTextColor;
      &:hover {
        fill: colors.$primaryColorHover;
        color: colors.$primaryColorHover;
        background: inherit;
        border: none;
      }
      &:active {
        fill: colors.$primaryColorActive;
        color: colors.$primaryColorActive;
        background: inherit;
        border: none;
      }
      &:disabled {
        fill: colors.$disabledColor;
        color: colors.$disabledColor;
        background: inherit;
        border: none;
      }
      &:focus {
        fill: colors.$primaryColorHover;
        color: colors.$primaryColorHover;
        background: inherit;
        border: none;
      }
    }
  }
}
