@use "assets/theme/variables/colors";
@use "assets/theme/variables/mixins";
@use "assets/theme/variables/borders";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/palette";
@use "assets/theme/variables/zIndexes";

.root {
  position: absolute;
  bottom: 0;
  right: 2%;
  width: 450px;
  padding: spacers.$spacer7;
  background-color: colors.$mainBackgroundColor;
  border-radius: borders.$borderRadius borders.$borderRadius 0 0;
  z-index: zIndexes.$FILE_UPLOAD_Z_INDEX;

  @include mixins.shadowMixin('lg');

  :global(.ant-collapse-header) {
    align-items: center;
    padding: 0 0 spacers.$spacer4;
    border-bottom: 1px solid colors.$borderColor;
  }

  :global(.ant-collapse-content) {
    max-height: 500px;
    overflow-y: auto;
  }

  :global(.ant-collapse-extra) {
    order: 2;
    line-height: 0;
    margin-left: spacers.$spacer6;
  }

  :global(.ant-collapse-header-text) {
    min-width: 0;
  }

  :global(.ant-collapse-content-box) {
    padding: spacers.$spacer4 0 0 !important;
  }
}

.uploadTrigger {
  display: none;
}

.activePanelIcon {
  transform: rotate(180deg);
}

.fileIcon {
  color: palette.$platinum300;
}

.item {
  padding: spacers.$spacer2 spacers.$spacer4;
  > div {
    line-height: 0;
  }
}

.status {
  white-space: nowrap;
}

.cancelButton {
  height: fit-content;
}

.upload {
  height: 0;
}
