.SM {
  border-radius: 10px !important;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 17px !important;
}

.BASE {
  border-radius: 12px !important;
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.L {
  border-radius: 16px !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 24px !important;
}

.XL {
  border-radius: 32px !important;
  font-size: 56px !important;
  font-weight: 600;
  line-height: 67px !important;
}
