@use "assets/theme/variables/spacers";

.root {
  height: 100%;

  :global(.ant-col) {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  :global(.ant-empty-footer) {
    display: flex;
    justify-content: center;
  }
}

.instruments {
  margin-bottom: spacers.$spacer6;
}

.content {
  flex: 1;
}
