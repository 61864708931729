@use "assets/theme/variables/spacers";
@use "assets/theme/variables/colors";
@use "assets/theme/variables/borders";

.root {
  background-color: colors.$mainBackgroundColor;
  padding: spacers.$spacer3;
  border-radius: borders.$borderRadius;
}

.fullHeight {
  height: 100%;
}
