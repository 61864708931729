@use "assets/theme/variables/spacers";
@use "assets/theme/variables/font";
@use "assets/theme/variables/palette";
@use "assets/theme/variables/colors";
@use "sass:color";

.root {
  align-items: center;
  display: flex;
  font-weight: 600;
  padding: calc(#{spacers.$spacer4} + 1.5px) spacers.$spacer5;
  line-height: 16.8px;
  box-shadow: none;
  justify-content: center;

  &:global(.ant-btn-sm) {
    font-size: font.$fontSizeSmall;
    padding: calc(#{spacers.$spacer4} - 1px) spacers.$spacer4;
    line-height: 14.4px;
  }

  &:global(.ant-btn-lg) {
    padding: calc(#{spacers.$spacer5} + 2.5px) spacers.$spacer5;
    line-height: 19.2px;
  }

  &:disabled {
    border: none;
  }

  &:global(.ant-btn-default) {
    color: colors.$buttonPrimaryColor;

    &:hover {
      border-color: transparent !important;
    }
    &:active {
      border-color: transparent !important;
      background-color: palette.$blue200;
    }
    &:disabled {
      background-color: colors.$disabledColor;
      color: colors.$disabledTextColor;
    }
  }

  &:global(.ant-btn-dangerous) {
    color: colors.$errorColor;

    &:hover {
      border-color: transparent !important;
    }
    &:active {
      border-color: transparent !important;
      background-color: palette.$poppy300;
    }
    &:disabled {
      background-color: colors.$disabledColor;
      color: colors.$disabledTextColor;
    }
  }

  &:global(.ant-btn-text) {
    &:hover {
      color: colors.$primaryColorHover !important;
    }
    &:active {
      color: colors.$primaryColorActive !important;
    }
  }
}

.suffixIcon {
  margin-left: spacers.$spacer3;
}

.onlyIcon {
  font-size: font.$fontSizeXxl !important;
  padding: spacers.$spacer4;

  &:global(.ant-btn-lg) {
    padding: calc(#{spacers.$spacer5} + 2px);
  }

  &:global(.ant-btn-sm) {
    font-size: font.$fontSizeSmall !important;
    padding: spacers.$spacer3;
    height: auto;
  }
}

.iconButton {
  background: inherit !important;
  padding: spacers.$spacer0 !important;
  color: palette.$devysGray500;

  &:global(.ant-btn-dangerous) {
    color: colors.$errorColor;

    &:hover {
      color: color.scale(colors.$errorColor, $lightness: -5%)  !important;
    }
    &:active {
      color: color.scale(colors.$errorColor, $lightness: -10%)  !important;
    }
    &:disabled {
      background-color: colors.$disabledColor;
      color: colors.$disabledTextColor;
    }
  }
}

.activeButton {
  &:global(.ant-btn-default) {
    border-color: transparent !important;
    background-color: palette.$blue200 !important;
    color: colors.$primaryColor;
  }

  &:global(.ant-btn-dangerous) {
    color: colors.$errorColor;
    border-color: transparent !important;
    background-color: palette.$poppy300;
  }

  &:global(.ant-btn-text) {
    color: colors.$primaryColorActive !important;
  }
}
