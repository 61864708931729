@use "assets/theme/variables/colors";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/borders";
@use "assets/theme/variables/font";

.root {
  padding: spacers.$spacer1 spacers.$spacer2;
  background-color: colors.$pageBackground;
  position: absolute;
  bottom: -(spacers.$spacer6);
  right: -(spacers.$spacer8);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}
