@use "assets/theme/variables/palette";
@use "assets/theme/variables/borders";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/sizes";

.root {
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 40px;
    background-color: palette.$blue100;
    border-radius: borders.$borderRadiusSm;
}
.text {
    color: palette.$gray650;
    margin: spacers.$spacer0 calc((spacers.$spacer2 + 1px));
}
.divider {
    margin: spacers.$spacer0;
    background-color: palette.$gray150;
    top: spacers.$spacer0;
    height: sizes.$iconButtonSize;
}
