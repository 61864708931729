@use "assets/theme/variables/colors";
@use "assets/theme/variables/spacers";
@use "assets/theme/variables/sizes";
@use "assets/theme/variables/borders";

.header {
  padding: spacers.$spacer4 spacers.$spacer5 0;
}

.content {
  background-color: colors.$mainBackgroundColor;
  min-height: 70vh;
  max-height: 70vh;
  height: 70vh;
  min-width: sizes.$layerSwitcherWidth;
  max-width: sizes.$layerSwitcherWidth;
  width: sizes.$layerSwitcherWidth;
  border-radius: borders.$borderRadiusMd;
  display: flex;
  flex-direction: column;
}

.closeButton {
  border: solid 1px colors.$borderColor;
  position: absolute;
  min-width: 32px !important;
  max-width: 32px !important;
  height: 32px !important;
  right: -16px !important;
  background-color: colors.$mainBackgroundColor;
}

.contentDivider {
  margin: spacers.$spacer3 0;
}

.listContainer {
  overflow-y: auto;
}

.item {
  :global(.ant-collapse-content-box) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.legend {
  margin-left: spacers.$spacer8 - 2;
}
