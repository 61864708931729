@use "assets/theme/variables/spacers";
@use "assets/theme/variables/font";
@use "assets/theme/variables/palette";
@use "assets/theme/variables/colors";
@use "assets/theme/variables/sizes";
@use "assets/theme/variables/borders";

.root {
  width: sizes.$iconButtonSize;
  height: sizes.$iconButtonSize;
  padding: spacers.$spacer;
  border-radius: borders.$borderRadiusSm;
}

.textOnly {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  background: inherit !important;
}
