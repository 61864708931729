@use "assets/theme/variables/breakpoints";
@use "assets/theme/variables/spacers";

.root {
  margin: auto;
  max-width: breakpoints.$screenXxl;
  padding: spacers.$spacer6 spacers.$spacer8;
}

.mobile {
  padding: spacers.$spacer5 spacers.$spacer3;
}

.fullWidth {
  max-width: 100%;
}

.fullHeight {
  height: 100%;

  > :global(.ant-col) {
    height: 100%;
  }
}
