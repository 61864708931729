@use "assets/theme/variables/spacers";

.settingsRadio {
  > label {
    width: 100%;
  }
}

.nestedItem {
  padding-left: spacers.$spacer8;
}

.heightFlagLabel {
  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.heightFlagRow {
  display: flex;
  justify-content: space-between;
}

.heightFlag {
  width: fit-content;

  :global(.ant-form-item-control-input-content, .ant-form-item-row, .ant-form-item-control) {
    width: fit-content;
    max-width: fit-content;
  }
}
