@mixin shadowMixin($size: 'default') {
  @if $size == 'sm' {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
  } @else if $size == 'md' {
    box-shadow: 0 2px 22px -2px rgba(0, 0, 0, 0.05), 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  } @else if $size == 'lg' {
    box-shadow: 0 10px 20px -4px rgba(0, 0, 0, 0.10), 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  } @else {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  }
}
