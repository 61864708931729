@use "assets/theme/variables/palette";
@use "assets/theme/variables/spacers";

.root {
  width: 100%;
  padding: spacers.$spacer4 spacers.$spacer5;

  &:hover {
    background-color: palette.$blue50;

    .rightIcon {
      display: block;
    }
  }
}

.deleteIcon {
  color: palette.$blue800;
}

.rightIcon {
  color: palette.$blue800;
  display: none;
}

.bottom {
  height: 20px;
}
